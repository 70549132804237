/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Button from '../components/Button';
import Icon from '../components/Icon';
import { MainLayout } from '../components/Layout';
import { Container } from '../components/LayoutComp';
import SEO from '../components/SEO';
import withI18next from '../components/withI18next';
import { isBrowser } from '../utils';
import getParamFromQueryString from '../utils/getParamFromQueryString';

const Main = styled.article`
  .partTop {
    padding-top: 2em;
  }
  .partStructure {
    background-color: #f2f2f2;
  }
  .partStructure,
  .partKey {
    padding-top: 2.5em;
    padding-bottom: 3em;
  }
  .title {
    font-size: 2rem;
    margin-bottom: 0.5em;
    margin-top: 0;
    em {
      color: #d22222;
      font-style: normal;
      display: block;
    }
  }

  @media only screen and (min-width: 922px) {
    .partTop {
      padding-top: 5em;
      padding-bottom: 5em;
    }
    .title {
      font-size: 2.5rem;
      em {
        display: inline-block;
      }
    }
  }
`;

const Purpose = styled.div`
  .content {
    max-width: 560px;
    margin-bottom: 0;
    padding-left: 1em;
    list-style: none;
    li {
      &:before {
        content: '◆';
        color: #d22222;
        width: 1.5em;
        margin-left: -1.5em;
        display: inline-block;
        text-align: center;
      }
      margin-bottom: 0.75em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .img {
    margin-top: 2em;
    margin-left: -20px;
    margin-right: -20px;
  }

  @media only screen and (min-width: 922px) {
    position: relative;

    .img {
      margin-top: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 40%;
      max-width: 560px;
      margin-left: 0;
      margin-right: 0;
      /* the margin bottom of p tag is 0.725 + padding of parent element */
      margin-bottom: -5em;
    }
  }
`;

const SubTitle = styled.h3`
  font-size: 18px;
  line-height: 1.2;
  margin-top: 1em;
  margin-bottom: 1em;

  &.center {
    max-width: 24em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  ${props =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}` : ''};

  em {
    color: #d22222;
    font-style: normal;
  }

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

const StructureList = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    flex-wrap: wrap;
  }
`;

const StructureItem = styled.div`
  padding: 1.5em;
  border-radius: 6px;
  background-color: white;
  margin-bottom: 1em;

  @media only screen and (min-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
    flex: 0 calc(33% - 20px);
  }

  @media only screen and (min-width: 922px) {
    flex: 0 calc(33% - 20px);
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;

    .icon {
      width: 26px;
      height: 26px;
      border-radius: 100%;
      background-color: #d22222;
      margin-right: 8px;
      color: white;
      padding: 2px 5px;
    }
    .title {
      font-size: 1em;
      margin: 0;
    }
  }
  .body {
    font-size: 14px;
  }
`;

const ActionWrapper = styled.div`
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
`;

const EntranceTest = ({ location, pageContext }) => {
  const testId = getParamFromQueryString(location.search, 'testId');
  const { lmsTestLink = '/:testId' } = pageContext;
  const targetTestLink = lmsTestLink.replace(':testId', testId);

  return (
    <StaticQuery
      query={graphql`
        query {
          girlInWhite: file(relativePath: { eq: "test/mock.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
    >
      {data => (
        <MainLayout hideReview>
          <SEO
            title="Mock Test!"
            description="Giúp học viên hiểu được trình độ hiện tại của bản thân, từ đó đưa ra kế hoạch ôn tập phù hợp."
            meta={pageContext.meta}
          />
          <Main>
            <section className="partTop">
              <Container>
                <h1 className="title">
                  Welcome to <em>Mock Test!</em>
                </h1>
                <div>
                  <SubTitle>Mục đích bài test:</SubTitle>
                  <Purpose>
                    <ul className="content">
                      <li>
                        Giúp học viên hiểu được trình độ hiện tại của bản thân,
                        từ đó đưa ra kế hoạch ôn tập phù hợp.
                      </li>
                      <li>
                        Giúp học viên làm quen với trình tự và áp lực của một
                        bài thi thật, từ đó đưa ra chiến thuật làm bài, biết
                        cách quản lí thời gian hợp lí.
                      </li>
                      <li>
                        Giúp giáo viên theo dõi kĩ càng hơn sự tiến bộ của học
                        sinh và kịp thời đưa ra lộ trình học phù hợp.
                      </li>
                    </ul>
                    <div className="img">
                      <Img
                        fluid={data.girlInWhite.childImageSharp.fluid}
                        alt="Dolenglish - entrance test"
                      />
                    </div>
                  </Purpose>
                </div>
              </Container>
            </section>

            <section className="partStructure">
              <Container>
                <SubTitle textAlign="center" marginBottom="1.5em">
                  Cấu trúc bài test gồm 3 phần:
                </SubTitle>
                <StructureList>
                  <StructureItem>
                    <div className="header">
                      <div className="icon">
                        <Icon type="Headphone" />
                      </div>
                      <h6 className="title">Listening</h6>
                    </div>
                    <div className="body">
                      Học viên làm thử 1 bài full test tương tự như bài thi thật
                      trong Listening. Học viên chỉ được nghe đoạn audio 1 lần.
                    </div>
                  </StructureItem>
                  <StructureItem>
                    <div className="header">
                      <div className="icon">
                        <Icon type="Book" />
                      </div>
                      <h6 className="title">Reading</h6>
                    </div>
                    <div className="body">
                      Học viên hoàn thành 1 full IELTS Reading test gồm 3
                      passages trong vòng 60 phút.
                    </div>
                  </StructureItem>
                  <StructureItem>
                    <div className="header">
                      <div className="icon">
                        <Icon type="Pen" />
                      </div>
                      <h6 className="title">Writing</h6>
                    </div>
                    <div className="body">
                      Học viên viết 1 bài essay hoàn chỉnh (ít nhất 250 từ) trả
                      lời một đề bài nghị luận cho sẵn.
                    </div>
                  </StructureItem>
                </StructureList>
                <ActionWrapper>
                  {isBrowser() && (
                    <Button to={targetTestLink} target="_blank">
                      Đăng nhập để làm bài
                    </Button>
                  )}
                </ActionWrapper>
              </Container>
            </section>
          </Main>
        </MainLayout>
      )}
    </StaticQuery>
  );
};

EntranceTest.propTypes = {
  location: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({}).isRequired
};

export default withI18next()(EntranceTest);
